import { query } from '@confluence/query-preloader-tools';
import { PAGE_SIZE } from './recentQueryConfig';

import { RecentContentMenuQuery } from './queries/RecentContentMenuQuery.graphql';

export function preloadRecentContentMenu(cloudId) {
	return query({
		query: RecentContentMenuQuery,
		variables: {
			cloudId,
			after: '',
			first: PAGE_SIZE,
			eventTypes: [],
		},
		fetchPolicy: 'network-only',
	});
}
