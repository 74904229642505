import gql from 'graphql-tag';

import { ContentMenuFragment } from './ContentMenuFragment.fragment';

export const RecentContentMenuQuery = gql`
	query RecentContentMenuQuery(
		$cloudId: ID!
		$first: Int
		$after: String
		$eventTypes: [ActivityEventType!]
	) {
		activities {
			myActivities {
				all(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								cloudIds: [$cloudId]
								eventTypes: $eventTypes
								objectTypes: [PAGE, BLOGPOST, WHITEBOARD, DATABASE, EMBED]
							}
						}
					]
					first: $first
					after: $after
				) {
					nodes {
						id
						timestamp
						object {
							id
							events {
								eventType
							}
							content {
								...ContentMenuFragment
							}
						}
					}
					edges {
						cursor
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
	${ContentMenuFragment}
`;

export const RecentlyCreatedByMeQuery = gql`
	query RecentlyCreatedByMeQuery($cql: String!, $cqlcontext: String!, $first: Int, $after: String) {
		search(cql: $cql, cqlcontext: $cqlcontext, first: $first, after: $after) {
			nodes {
				url
				content {
					...ContentMenuFragment
					space {
						alias
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${ContentMenuFragment}
`;
