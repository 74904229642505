import React, { useContext, useCallback, useRef, type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';

import { SSRMouseEventWrapper, SSR_NAV_PAGES_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useRouteDataRef, useRouteUrl } from '@confluence/route-manager';
import { LoadableLazy } from '@confluence/loadable';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { GO_TO_RECENT_SHORTCUT } from '@confluence/shortcuts';
import {
	APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { HOME } from '@confluence/named-routes';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { KeyboardShortcutWrapper } from '../KeyboardShortcutWrapper';
import { i18n } from '../globalNavigationTranslations';
import { ClockIconComponent } from '../GlobalNavigationIcons';
import { preloadRecentContentMenu } from '../../ContentMenu/preloadRecentContentMenu';
import { ContentMenuLoadingState } from '../../ContentMenu/ContentMenuStates';
import { useFlyout } from '../FlyoutStore';

import { useGlobalItemVisibility } from './useGlobalItemVisibility';
import type { GlobalItemProps } from './globalItemProps';

export const RecentContentMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RecentContentMenu" */
				'../../ContentMenu/RecentContentMenu'
			)
		).RecentContentMenu,
	loading: () => <ContentMenuLoadingState />,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireRecentsClickedAnalytics,
);

const MENU_ID = 'recent';

export const RecentItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const { isFlyoutOpen, openFlyout, closeFlyout } = useFlyout(MENU_ID);

	const routeDataRef = useRouteDataRef();
	const { cloudId } = useSessionData();
	const isMounted = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const abortExperienceTracker = useCallback(() => {
		experienceTracker.abort({
			name: APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
			reason: 'Primary dropdown menu: closed by user',
			attributes: {
				navVersion: '4',
				dropdownType: 'recent',
			},
		});
	}, [experienceTracker]);

	const startExperienceTracker = useCallback(() => {
		experienceTracker.start({
			name: APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
			timeout: ExperienceTimeout.NAVIGATION_LOAD,
			attributes: {
				navVersion: '4',
				dropdownType: 'recent',
			},
		});
	}, [experienceTracker]);

	const onOpenChange = useCallback(
		(newIsOpen: boolean) => {
			if (!isMounted.current) {
				isMounted.current = true;
				return;
			}
			if (!newIsOpen) {
				abortExperienceTracker();
				isFlyoutOpen && closeFlyout();
				void fireClickAnalytics(false);
			} else {
				openFlyout();
			}
		},
		[abortExperienceTracker, isFlyoutOpen, closeFlyout, fireClickAnalytics, openFlyout],
	);

	const onShortcutTriggered = useCallback(() => {
		if (!isFlyoutOpen) {
			void fireClickAnalytics(true, true, isHidden);
			startExperienceTracker();
			openFlyout();
		}
	}, [fireClickAnalytics, isFlyoutOpen, openFlyout, startExperienceTracker, isHidden]);

	const onClick = useCallback(() => {
		if (!isFlyoutOpen) {
			openFlyout();
			startExperienceTracker();
			void fireClickAnalytics(true); //if we clicked on it we know it's not hidden or fired via keyboard shortcut
		} else {
			closeFlyout();
		}
	}, [fireClickAnalytics, isFlyoutOpen, openFlyout, closeFlyout, startExperienceTracker]);

	const onHover = useCallback(() => {
		if (!isFlyoutOpen) {
			void RecentContentMenuLoader.preload();
			void preloadRecentContentMenu(cloudId);
		}
	}, [isFlyoutOpen, cloudId]);

	const isSelected = useRouteUrl(isOnRecentRoute);
	const shouldHide = useGlobalItemVisibility(
		MENU_ID,
		isSelected || isFlyoutOpen,
		isHidden,
		peekingId,
		setPeekingId,
	);

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_PAGES_BUTTON_METRIC}>
			<KeyboardShortcutWrapper
				keyboardShortcut={
					(isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')) ||
					(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut'))
						? undefined
						: GO_TO_RECENT_SHORTCUT
				}
				isAppNavigationShortcut
				onShortcutTriggered={onShortcutTriggered}
			>
				{!shouldHide && (
					/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
					<div onMouseEnter={onHover}>
						<FlyoutMenuItem onOpenChange={onOpenChange} isOpen={isFlyoutOpen}>
							<FlyoutMenuItemTrigger
								iconBefore={ClockIconComponent}
								onClick={onClick}
								isSelected={isSelected}
							>
								<FormattedMessage {...i18n.recent} />
							</FlyoutMenuItemTrigger>
							<FlyoutMenuItemContent
								onClose={() => isFlyoutOpen && closeFlyout()}
								autoFocus={false}
							>
								<RecentContentMenuLoader />
							</FlyoutMenuItemContent>
						</FlyoutMenuItem>
					</div>
				)}
			</KeyboardShortcutWrapper>
		</SSRMouseEventWrapper>
	);
};

const isOnRecentRoute = {
	selector: (routeUrl: string | undefined) => routeUrl === HOME.toUrl({ view: 'recent' }),
};
