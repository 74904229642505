import set from 'lodash/set';

import { CONTEXT_PATH } from '@confluence/named-routes';
import { expVal } from '@confluence/feature-experiments';

import {
	RecentContentMenuQuery,
	RecentlyCreatedByMeQuery,
} from './queries/RecentContentMenuQuery.graphql';
import { ActivityEventType } from './queries/__types__/RecentContentMenuQuery';

export const PAGE_SIZE = 30;

export const WORKED_ON_EVENT_TYPES = [
	ActivityEventType.COMMENTED,
	ActivityEventType.EDITED,
	ActivityEventType.PUBLISHED,
	ActivityEventType.UPDATED,
];

export type FilterType = 'ALL' | 'WORKED_ON' | 'CREATED_BY_ME';

const transformActivitiesNodeLegacyUrl = (activitiesNode) => {
	if (!activitiesNode?.object?.content?.links?.webui) return activitiesNode;

	return {
		...activitiesNode,
		object: {
			...activitiesNode.object,
			url: `${CONTEXT_PATH}${activitiesNode.object.content.links.webui}`,
			content: {
				...activitiesNode.object.content,
			},
		},
	};
};

const getAllRecentQueryOptions = (cloudId) => ({
	query: RecentContentMenuQuery,
	initialVars: {
		cloudId,
		after: '',
		first: PAGE_SIZE,
		eventTypes: [],
	},
	transformNodes: (data) =>
		(data?.activities?.myActivities?.all?.nodes || []).map(transformActivitiesNodeLegacyUrl),
	getPaginationParams: (data) => {
		const allActivity = data?.activities?.myActivities?.all;
		return {
			endCursor: allActivity?.edges?.[allActivity.edges?.length - 1]?.cursor || '',
			hasNextPage: allActivity?.pageInfo?.hasNextPage || false,
		};
	},
	updatePaginationResults: (prev, fetchMoreResult) => {
		const prevNodes = prev.activities?.myActivities?.all?.nodes || [];
		const fetchMoreNodes = fetchMoreResult.activities?.myActivities?.all?.nodes || [];
		const mergedNodes = [...prevNodes, ...fetchMoreNodes];
		const result = { ...fetchMoreResult };
		set(result, 'activities.myActivities.all.nodes', mergedNodes);
		return result;
	},
});

const getWorkedOnQueryOptions = (cloudId) => ({
	query: RecentContentMenuQuery,
	initialVars: {
		cloudId,
		after: '',
		first: PAGE_SIZE,
		eventTypes: WORKED_ON_EVENT_TYPES,
	},
	transformNodes: (data) =>
		(data?.activities?.myActivities?.all?.nodes || []).map(transformActivitiesNodeLegacyUrl),
	getPaginationParams: (data) => {
		const allActivity = data?.activities?.myActivities?.all;
		return {
			endCursor: allActivity?.edges?.[allActivity.edges?.length - 1]?.cursor || '',
			hasNextPage: allActivity?.pageInfo?.hasNextPage || false,
		};
	},
	updatePaginationResults: (prev, fetchMoreResult) => {
		const prevNodes = prev.activities?.myActivities?.all?.nodes || [];
		const fetchMoreNodes = fetchMoreResult.activities?.myActivities?.all?.nodes || [];
		const mergedNodes = [...prevNodes, ...fetchMoreNodes];
		const result = { ...fetchMoreResult };
		set(result, 'activities.myActivities.all.nodes', mergedNodes);
		return result;
	},
});

const mapCreatedByMeTabNodes = (node) => {
	return {
		...node,
		timestamp: node?.content?.metadata?.currentuser?.lastcontributed?.when,
		object: {
			url: `${CONTEXT_PATH}${node.url}`,
			content: node.content,
		},
	};
};

const getCreatedByMeQueryOptions = () => {
	const userField = expVal<boolean>(
		'confluence_frontend_page_ownership_recent_tab_page',
		'isEnabled',
		false,
	)
		? 'owner'
		: 'creator';
	return {
		query: RecentlyCreatedByMeQuery,
		initialVars: {
			cql: `type in (page, blogpost, embed, whiteboard, database) and ${userField}=currentUser() and created >= startOfDay("-90d") order by created desc`,
			cqlcontext: `{"contentStatuses":["draft", "current"]}`,
			after: '',
			first: PAGE_SIZE,
		},
		transformNodes: (data) => (data?.search?.nodes || []).map(mapCreatedByMeTabNodes),
		getPaginationParams: (data) => ({
			endCursor: data?.search?.pageInfo?.endCursor || '',
			hasNextPage: data?.search?.pageInfo?.hasNextPage || false,
		}),
		updatePaginationResults: (prev, fetchMoreResult) => {
			const prevNodes = prev.search?.nodes || [];
			const fetchMoreNodes = fetchMoreResult.search?.nodes || [];
			const mergedNodes = [...prevNodes, ...fetchMoreNodes];
			const result = { ...fetchMoreResult };
			set(result, 'search.nodes', mergedNodes);
			return result;
		},
	};
};

type QueryOptions = {
	query: any;
	initialVars: any;
	transformNodes?: any;
	getPaginationParams?: any;
	updatePaginationResults?: any;
};

export const getQueryOptions = (cloudId: string) => {
	const queryOptions: Record<FilterType, QueryOptions> = {
		ALL: getAllRecentQueryOptions(cloudId),
		WORKED_ON: getWorkedOnQueryOptions(cloudId),
		CREATED_BY_ME: getCreatedByMeQueryOptions(),
	};
	return queryOptions;
};
