/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivityEventType {
  ASSIGNED = "ASSIGNED",
  COMMENTED = "COMMENTED",
  CREATED = "CREATED",
  EDITED = "EDITED",
  LIKED = "LIKED",
  PUBLISHED = "PUBLISHED",
  TRANSITIONED = "TRANSITIONED",
  UNASSIGNED = "UNASSIGNED",
  UPDATED = "UPDATED",
  VIEWED = "VIEWED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecentContentMenuQuery
// ====================================================

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_events {
  eventType: ActivityEventType | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_space {
  id: string | null;
  name: string | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_links {
  webui: string | null;
  editui: string | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata_currentuser_favourited {
  isFavourite: boolean | null;
  favouritedDate: string | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata_currentuser_lastcontributed {
  status: string | null;
  when: string | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata_currentuser {
  favourited: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata_currentuser_favourited | null;
  lastcontributed: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata_currentuser_lastcontributed | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata {
  currentuser: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata_currentuser | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_emoji_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_emoji {
  nodes: (RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_emoji_nodes | null)[] | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object_content {
  id: string | null;
  type: string | null;
  title: string | null;
  space: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_space | null;
  links: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_links | null;
  metadata: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_metadata;
  emoji: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content_emoji | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes_object {
  id: string;
  events: RecentContentMenuQuery_activities_myActivities_all_nodes_object_events[] | null;
  content: RecentContentMenuQuery_activities_myActivities_all_nodes_object_content | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_nodes {
  id: string;
  timestamp: string | null;
  object: RecentContentMenuQuery_activities_myActivities_all_nodes_object | null;
}

export interface RecentContentMenuQuery_activities_myActivities_all_edges {
  cursor: string;
}

export interface RecentContentMenuQuery_activities_myActivities_all_pageInfo {
  hasNextPage: boolean;
}

export interface RecentContentMenuQuery_activities_myActivities_all {
  nodes: RecentContentMenuQuery_activities_myActivities_all_nodes[];
  edges: (RecentContentMenuQuery_activities_myActivities_all_edges | null)[] | null;
  pageInfo: RecentContentMenuQuery_activities_myActivities_all_pageInfo;
}

export interface RecentContentMenuQuery_activities_myActivities {
  all: RecentContentMenuQuery_activities_myActivities_all | null;
}

export interface RecentContentMenuQuery_activities {
  myActivities: RecentContentMenuQuery_activities_myActivities | null;
}

export interface RecentContentMenuQuery {
  activities: RecentContentMenuQuery_activities | null;
}

export interface RecentContentMenuQueryVariables {
  cloudId: string;
  first?: number | null;
  after?: string | null;
  eventTypes?: ActivityEventType[] | null;
}
